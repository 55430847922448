import {
  get,
  getOr,
  identity,
  isEmpty,
  mergeAll,
  negate,
  omit,
  overSome,
  pipe,
  rest,
  slice,
  some,
} from 'lodash/fp'
import { createSelector } from 'reselect'

import { everyFalse } from '@masterplandev/utils'

import { SingularOutput } from '@/core/selectors.types'
import { companySelector as companySignupSelector } from '@/signup/selectors'

export const rootSelector = createSelector<Record<string, any>, any, any>(
  getOr({}, 'company'),
  identity,
)

const createMetaSelector = (url) =>
  pipe([getOr({}, ['company', url]), omit(['data', 'loaded'])])

const createDataSelector = (url: string) =>
  createSelector(rootSelector, get([url, 'data']))

const createRequiresFetchSelector = (
  metaSelector,
  paginationSelector = identity,
  dataSelector = identity,
) =>
  createSelector(
    [metaSelector, paginationSelector, dataSelector],
    // @ts-ignore
    ({ fetching, fetched, failed }, { from, size }, data) =>
      // @ts-ignore To be confirmed if this syntax works. Leaving as it is while moving file to TS.
      !some(null, [
        fetching,
        fetched,
        failed,
        pipe([slice(from, size), negate(overSome([some(isEmpty), isEmpty]))])(
          data,
        ),
      ]),
  )

export const companyMetaSelector = createMetaSelector('/company')

export const companyDataSelector = createSelector(
  [createDataSelector('/company'), companySignupSelector],
  rest(mergeAll),
)

export const companyLoadingSelector = createSelector(
  [companyMetaSelector],
  ({ fetched, failed }) => everyFalse([fetched, failed]),
)

export const companyRequiresFetchSelector = createSelector(
  [createRequiresFetchSelector(companyMetaSelector)],
  (requiresFetch) => requiresFetch,
) as SingularOutput<boolean>

export const companyLogoSelector = createSelector(
  companyDataSelector,
  get('logo_id'),
)
export const companyBackgroundSelector = createSelector(
  companyDataSelector,
  get('background_image_id'),
)
export const companyNameSelector = createSelector(
  companyDataSelector,
  get('name'),
)
export const companyColorSelector = createSelector(
  companyDataSelector,
  get('color'),
)
