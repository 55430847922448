import { includes } from 'lodash/fp'

export const requestSanitizer = (
  request,
  headersToReset = ['x-auth-token', 'Authorization'],
) => {
  const sanitizedHeaders = {
    ...request.headers,
    ...headersToReset.reduce((acc, header) => {
      if (request.headers?.[header]) {
        acc[header] = null
      }
      return acc
    }, {}),
  }

  if (includes('/auth/', request.url)) {
    return {
      ...request,
      headers: sanitizedHeaders,
      body: null,
    }
  }

  if (includes('/chatbot/lecture', request.url)) {
    return {
      ...request,
      headers: sanitizedHeaders,
      body: null,
    }
  }

  return {
    ...request,
    headers: sanitizedHeaders,
  }
}

export default requestSanitizer
