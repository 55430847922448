import {
  curry,
  filter,
  flatMap,
  get,
  getOr,
  identity,
  map,
  omit,
  pick,
  pipe,
  rest,
  size,
  values,
} from 'lodash/fp'
import { createSelector } from 'reselect'

import { everyFalse, injectProps } from '@masterplandev/utils'

import { POLL_TYPE_CERTIFICATE } from '@/core/constants/constants'
import { FEATURE_SIGNUP_POLL } from '@/core/constants/features'
import { createIsFeatureSelector, isStudentSelector } from '@/user/selectors'

import { buildUrl } from './utils'

export const injectPollTypeProp = curry((type, selector) =>
  injectProps({ type }, selector),
)

export const rootSelector = ({ poll }, props) => get(buildUrl(props), poll)

export const metaSelector = createSelector(rootSelector, omit('data'))
export const dataSelector = createSelector(rootSelector, getOr({}, 'data'))

export const errorSelector = createSelector(metaSelector, get('error'))

export const errorStatusSelector = createSelector(
  metaSelector,
  get('error_status'),
)

export const fetchedSelector = createSelector(metaSelector, get('fetched'))
export const fetchingSelector = createSelector(metaSelector, get('fetching'))

export const loadingSelector = createSelector(
  isStudentSelector,
  metaSelector,
  (student, { fetched, failed }) => student && !fetched && !failed,
)

export const isSubmittedSelector = createSelector(
  dataSelector,
  get('is_completed'),
)

/*
 * Check if signup poll has been submitted by the user.
 * If signup poll feature flag is disabled fake submitted response
 * to prevent user accessing /signup/poll step
 */
export const isSignupPollSubmittedSelector = createSelector(
  injectPollTypeProp('signup', isSubmittedSelector),
  createIsFeatureSelector(FEATURE_SIGNUP_POLL),
  (result, enabled) => (enabled ? result : true),
)

export const requiresFetchSelector = createSelector(
  isStudentSelector,
  metaSelector,
  (student, meta) =>
    student &&
    pipe([pick(['fetched', 'failed', 'fetching']), values, everyFalse])(meta),
)

export const pollIdSelector = createSelector(dataSelector, get('id'))
export const titleSelector = createSelector(dataSelector, get('title'))
export const textSelector = createSelector(dataSelector, get('text'))
export const sectionsSelector = createSelector(dataSelector, get('sections'))
export const sectionsCountSelector = createSelector(sectionsSelector, size)

export const allQuestionsSelector = createSelector(
  sectionsSelector,
  flatMap(getOr([], 'questions')),
)

export const injectCertificatePollProps = (selector) =>
  createSelector([rest(identity)], ([state, props]) =>
    injectProps({ type: POLL_TYPE_CERTIFICATE }, selector)(state, props),
  )

export const certificatePollRequiredQuestionsSelector = createSelector(
  injectCertificatePollProps(sectionsSelector),
  (sections) => {
    const questions = flatMap('questions', sections)
    return filter((question) => !question.optional, questions)
  },
)

export const certificatePollRequiredQuestionsIdsSelector = createSelector(
  certificatePollRequiredQuestionsSelector,
  map('id'),
)
