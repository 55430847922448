import * as Sentry from '@sentry/react'
import { isEmpty, isNil, omit, omitBy, pipe } from 'lodash/fp'
import LogRocket from 'logrocket'
import posthog from 'posthog-js'
import { ReactNode, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Userpilot } from 'userpilot'

import {
  anonymizeUserData,
  eventTracker,
  getInitials,
  mapKeysTemplate,
} from '@masterplandev/utils'

import { Department } from '@/api/generated-api-and-types'
import { useAuth } from '@/auth/hooks/useAuth'
import { companyDataSelector } from '@/company/selectors'
import { FEATURE_USERPILOT } from '@/core/constants/features'
import { useSearchParam } from '@/core/hooks/useSearchParam'
import { totalScoreSelector } from '@/dashboard/selectors'
import {
  createIsFeatureSelector,
  dataSelector as userSelector,
} from '@/user/selectors'
import { useUsersDetails } from '@/users/queries/useUsersDetails'

// eslint-disable-next-line react-refresh/only-export-components
export const getUserProperties = (userData) =>
  pipe([omit(['extra_data', 'lang']), omitBy(isNil)])(userData)

const getCompanyProperties = (companyData) =>
  pipe([omit(['background_image_id', 'color', 'logo_id']), omitBy(isNil)])(
    companyData,
  )

const mixpanelUserData = mapKeysTemplate({
  email: '$email',
  name: '$name',
  first_name: '$first_name',
  last_name: '$last_name',
})

interface Props {
  children: ReactNode
  company?: Record<string, any>
  totalScore?: number

  isUserpilotEnabled?: boolean
  user?: Record<string, any>
  department?: Department | null
}

export function AppManagerIdentifyPure({
  children,
  company = {},
  totalScore = 0,
  user = {},
  isUserpilotEnabled = false,
  department = null,
}: Props) {
  const googleAdsParam = useSearchParam('mixsource')
  const { isAuthenticated } = useAuth()
  const identify = useCallback(() => {
    if (isAuthenticated) {
      const userData = {
        ...user,
        department,
        locale_code: user?.lang,
        Company: company?.name,
        total_score: totalScore,
        invite_limit: user?.licenses?.total,
        'Admin Link': `${window.location.origin}/admin/user/edit/?id=${user?.id}`,
        'Last Login': new Date(),
      }
      const companyData = {
        ...company,
        id: company?.id,
        support_person: company?.support_person?.name,
      }

      const userProperties = getUserProperties(userData)
      const companyProperties = getCompanyProperties(companyData)

      const userSafeProperties = anonymizeUserData(userProperties)
      const companySafeProperties = anonymizeUserData(companyProperties)

      if (isUserpilotEnabled) {
        Userpilot.identify(user?.id, {
          ...userSafeProperties,
          company: companySafeProperties,
        })
      }

      posthog.identify(user?.id, {
        ...userSafeProperties,
        company: companySafeProperties,
      })

      LogRocket.identify(user?.id, userSafeProperties)
      if (user) {
        Sentry.setUser({
          id: user.id,
          username: getInitials(`${user.first_name} ${user.last_name}`),
        })
        Sentry.setContext('User Details', {
          features: user.features,
        })
      }
      if (company) {
        Sentry.setTag('companyId', company.id)
        Sentry.setContext('Company', {
          id: company.id,
          name: company.name,
        })
      }

      window.dataLayer.push({ userID: user?.id })

      eventTracker.identify(user?.id)
      eventTracker.register({ user_id: user?.id })
      eventTracker.people.set_once({
        $created: new Date(),
      })

      // @ts-ignore
      eventTracker.people.set(mixpanelUserData(userProperties))

      if (googleAdsParam) {
        eventTracker.register({ 'Ads Campaign': googleAdsParam })
      }
    }
  }, [
    isAuthenticated,
    user,
    company,
    totalScore,
    department,
    isUserpilotEnabled,
    googleAdsParam,
  ])

  useEffect(() => {
    if (isEmpty(user) || isEmpty(company) || !totalScore || !department) {
      identify()
    }
  }, [user, company, totalScore, department, identify])

  return children
}

// @ts-ignore
const mapStateToProps = createStructuredSelector({
  isUserpilotEnabled: createIsFeatureSelector(FEATURE_USERPILOT),
  company: companyDataSelector,
  user: userSelector,
  totalScore: totalScoreSelector,
})

const AppManagerConfig = (props) => {
  const { data } = useUsersDetails(props?.user?.id)

  return <AppManagerIdentifyPure {...props} department={data?.department} />
}

export const AppManagerIdentify = connect(mapStateToProps)(AppManagerConfig)
