import {
  concat,
  defaults,
  eq,
  filter,
  flatMap,
  get,
  getOr,
  gt,
  map,
  omit,
  pick,
  pipe,
  placeholder,
  size,
  sum,
  values,
} from 'lodash/fp'
import { Selector, createSelector } from 'reselect'

import { everyFalse, getPercentage, passProps } from '@masterplandev/utils'

import {
  Bookmarks,
  Course,
  CurrentLecture,
  FundamentalScore,
  Score,
} from '@/api/generated-api-and-types'
import {
  FAILED,
  FETCHED,
  FETCHING,
  STATUS_COMPLETED,
  STATUS_STARTED,
} from '@/core/constants/constants'
import type { ResourceState } from '@/core/types/ResourceState'
import buildLectureLink from '@/core/utils/links/buildLectureLink'
import createDeepTextSearch from '@/core/utils/search/createDeepTextSearch'

import {
  BOOKMARKS_ENDPOINT,
  COURSE_ENDPOINT,
  CURRENT_LECTURE_ENDPOINT,
  SCORE_ENDPOINT,
} from './constants'
import { DashboardState } from './types/DashboardState'

export const searchValueSelector = createSelector(
  passProps,
  get('values.search'),
)

type State = {
  dashboard: DashboardState
}

export const rootSelector: Selector<State, DashboardState> = getOr(
  {} as DashboardState,
  'dashboard',
)

export const courseSelector = createSelector(
  rootSelector,
  getOr({} as ResourceState<Course>, COURSE_ENDPOINT),
)

export const courseMetaSelector = createSelector(courseSelector, omit('data'))

export const courseFetchingSelector = createSelector(
  courseMetaSelector,
  getOr(false, FETCHING),
)

export const courseFetchedSelector = createSelector(
  courseMetaSelector,
  getOr(false, FETCHED),
)

export const courseDataSelector = createSelector(
  courseSelector,
  getOr({} as Course, 'data'),
)

export const courseNumberOfLecturesSelector = createSelector(
  courseDataSelector,
  pick(['num_lectures', 'num_trial_lectures']),
)

export const courseRequiresFetchSelector = createSelector(
  courseMetaSelector,
  pipe([pick([FETCHED, FETCHING, FAILED]), values, everyFalse]),
)

export const titleSelector = createSelector(courseDataSelector, get('title'))

export const courseProgressSelector = createSelector(
  courseDataSelector,
  getOr({} as NonNullable<Course['progress']>, 'progress'),
)

export const isCourseCompletedSelector = createSelector<any, any, boolean>(
  courseProgressSelector,
  pipe([get('status'), eq(STATUS_COMPLETED)]),
)

export const allCourseTopicsSelector = createSelector(
  courseDataSelector,
  pipe([get('themes'), flatMap(get('topics'))]),
)

export const courseCompletedTopicsSelector = createSelector(
  allCourseTopicsSelector,
  filter(pipe([get('progress.status'), eq(STATUS_COMPLETED)])),
)

export const courseStartedTopicsSelector = createSelector(
  allCourseTopicsSelector,
  filter(pipe([get('progress.status'), eq(STATUS_STARTED)])),
)

export const courseSlugSelector = createSelector(
  courseDataSelector,
  get('slug'),
)

export const courseThemesSlugSelector = createSelector(
  [courseDataSelector, courseSlugSelector],
  (courseData, courseSlug) =>
    pipe([get('themes'), map(get('slug')), concat(courseSlug)])(courseData),
)

export const completedCourseTopicsByThemeSelector = createSelector(
  [courseDataSelector],
  pipe([
    get('themes'),
    map((theme) =>
      defaults(theme, {
        topics: pipe([
          get('topics'),
          filter(pipe([get('progress.status'), eq(STATUS_COMPLETED)])),
        ])(theme),
      }),
    ),
    filter(pipe([get('topics'), size, gt(placeholder, 0)])),
  ]),
)

export const numCompletedCourseTopicsSelector = createSelector(
  [completedCourseTopicsByThemeSelector],
  pipe([map(pipe([get('topics'), size])), sum]),
)

export const numFundamentalLecturesSelector = createSelector(
  courseDataSelector,
  pipe([
    get('themes'),
    map(pipe([get('topics'), map(get('num_lectures')), sum])),
    sum,
  ]),
)

export const scoreSelector = createSelector(
  rootSelector,
  getOr({} as ResourceState<Score>, SCORE_ENDPOINT),
)

export const scoreMetaSelector = createSelector(scoreSelector, omit('data'))

export const scoreFetchingSelector = createSelector(
  scoreMetaSelector,
  getOr(false, FETCHING),
)

export const scoreFetchedSelector = createSelector(
  scoreMetaSelector,
  getOr(false, FETCHED),
)

export const scoreDataSelector = createSelector(
  scoreSelector,
  getOr({} as Score, 'data'),
)

export const lastScoreSelector = createSelector(
  scoreDataSelector,
  getOr(null, 'last_score'),
)

export const scoreRequiresFetchSelector = createSelector(
  scoreMetaSelector,
  pipe([pick([FETCHED, FETCHING, FAILED]), values, everyFalse]),
)

export const scoreLoadingSelector = createSelector(
  scoreMetaSelector,
  ({ fetched, failed }) => !fetched && !failed,
)

export const fundamentalScoreSelector = createSelector(
  scoreDataSelector,
  getOr({} as FundamentalScore, 'fundamental_score'),
)

export const fundamentalScoresSelector = createSelector(
  fundamentalScoreSelector,
  (data) => {
    if (data && 'award_scores' in data && 'max_score' in data) {
      return {
        ...(data.max_score && { max: data.max_score }),
        ...data.award_scores,
      }
    }

    return null
  },
)

export const totalFundamentalScoreSelector = createSelector(
  fundamentalScoreSelector,
  get('total_score'),
)

export const totalScoreSelector = createSelector(
  scoreDataSelector,
  get('total_score'),
)

export const fundamentalScorePercentageSelector = createSelector(
  fundamentalScoreSelector,
  ({ total_score, max_score }) => getPercentage(total_score, max_score),
)

export const courseCompletionPercentageSelector = createSelector(
  [courseNumberOfLecturesSelector, courseProgressSelector],
  (numberOfLectures, courseProgress) => {
    const { num_lectures } = numberOfLectures
    const { num_completed_lectures } = courseProgress

    return getPercentage(num_completed_lectures, num_lectures)
  },
)

export const currentLectureSelector = createSelector(
  rootSelector,
  getOr({} as ResourceState<CurrentLecture>, CURRENT_LECTURE_ENDPOINT),
)

export const currentLectureMetaSelector = createSelector(
  currentLectureSelector,
  omit('data'),
)

export const currentLectureFetchingSelector = createSelector(
  currentLectureMetaSelector,
  getOr(false, FETCHING),
)

export const currentLectureFetchedSelector = createSelector(
  currentLectureMetaSelector,
  getOr(false, FETCHED),
)

export const currentLectureFailedSelector = createSelector(
  currentLectureMetaSelector,
  getOr(false, FAILED),
)

export const currentLectureDataSelector = createSelector(
  currentLectureSelector,
  getOr({} as CurrentLecture, 'data'),
)

export const currentLectureRequiresFetchSelector = createSelector(
  currentLectureMetaSelector,
  pipe([pick([FETCHED, FETCHING, FAILED]), values, everyFalse]),
)

export const currentLectureUrlSelector = createSelector(
  currentLectureDataSelector,
  currentLectureFailedSelector,
  passProps,
  ({ slug, topic }, failed: boolean, props) => {
    if (!topic || !topic.slug || failed) {
      return null
    }

    return buildLectureLink({
      topic: topic.slug,
      lecture: slug,
      suffix: slug ? 'info' : null,
      skill: props?.skill,
      course: props?.course,
    })
  },
)

export const currentTopicDataSelector = createSelector(
  currentLectureDataSelector,
  getOr({}, 'topic'),
)

export const currentTopicTypeSelector = createSelector(
  currentTopicDataSelector,
  get('type'),
)

export const currentTopicSlugSelector = createSelector(
  currentTopicDataSelector,
  get('slug'),
)

export const bookmarksSelector = createSelector(
  rootSelector,
  getOr({} as ResourceState<Bookmarks>, BOOKMARKS_ENDPOINT),
)

export const bookmarksMetaSelector = createSelector(
  bookmarksSelector,
  omit('data'),
)

export const bookmarksDataSelector = createSelector(
  bookmarksSelector,
  getOr({}, 'data'),
)

export const bookmarksFetchingSelector = createSelector(
  bookmarksMetaSelector,
  getOr(false, FETCHING),
)

export const bookmarksFetchedSelector = createSelector(
  bookmarksMetaSelector,
  getOr(false, FETCHED),
)

export const bookmarksRequiresFetchSelector = createSelector(
  bookmarksMetaSelector,
  pipe([pick([FETCHED, FETCHING, FAILED]), values, everyFalse]),
)

export const filteredBookmarksSelector = createSelector(
  [bookmarksDataSelector, searchValueSelector],
  (bookmarks, search) =>
    createDeepTextSearch({
      search,
      searchIn: ['title', 'text'],
      path: ['topics', 'lectures', 'bookmarks'],
    })(bookmarks),
)
