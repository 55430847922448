import { startsWith } from 'lodash/fp'
import { combineActions, handleActions } from 'redux-actions'
import { getActionTypes } from 'redux-axios-middleware'

import actions from '@/actions'
import {
  API_URL_INVITES,
  API_URL_SELF_INVITE,
  LOCATION_CHANGE,
} from '@/core/constants/constants'
import clearFailed from '@/core/utils/redux/clearFailed'
import mergeErrorAction from '@/core/utils/redux/mergeErrorAction'
import mergeRequestAction from '@/core/utils/redux/mergeRequestAction'
import mergeSuccessAction from '@/core/utils/redux/mergeSuccessAction'
import modifyRequestUrl from '@/core/utils/redux/modifyRequestUrl'

export const modifySignupUrl = modifyRequestUrl((url) => {
  if (startsWith(API_URL_SELF_INVITE, url)) {
    return API_URL_SELF_INVITE
  }

  if (startsWith(API_URL_INVITES, url)) {
    return API_URL_INVITES
  }

  return url
})

const [GET_REQUEST, GET_SUCCESS, GET_ERROR] = getActionTypes({
  type: actions.signup.get,
})
const [
  GET_SELF_INVITE_REQUEST,
  GET_SELF_INVITE_SUCCESS,
  GET_SELF_INVITE_ERROR,
] = getActionTypes({ type: actions.signup.selfInvite.get })

export const initialState = {}

export default handleActions(
  {
    [LOCATION_CHANGE]: clearFailed,
    [combineActions(GET_REQUEST, GET_SELF_INVITE_REQUEST)]:
      modifySignupUrl(mergeRequestAction),
    [combineActions(GET_SUCCESS, GET_SELF_INVITE_SUCCESS)]:
      modifySignupUrl(mergeSuccessAction),
    [combineActions(GET_ERROR, GET_SELF_INVITE_ERROR)]:
      modifySignupUrl(mergeErrorAction),
  },
  initialState,
)
