import { captureException } from '@sentry/react'
import { noop } from 'lodash/fp'
import React, { useRef } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { FormattedMessage, IntlProvider } from 'react-intl'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Icon,
} from '@masterplandev/ui'

import { ErrorDetailsOverlay } from '@/core/components/ErrorDetailsOverlay'
import { UIProvider } from '@/core/components/UIProvider'
import { APP_LOCALE } from '@/locale/constants'
import type { AppLocale } from '@/locale/types/Locale'
import { getLocaleFromNavigator } from '@/locale/utils/getLocaleFromNavigator'

export function Fallback({ error, resetErrorBoundary }: FallbackProps) {
  const resetRef = useRef(null)

  const locale = getLocaleFromNavigator(APP_LOCALE)

  const messages: Record<AppLocale, object> = {
    de: {
      title: 'Ein unerwarteter Fehler ist aufgetreten',
      text: 'Etwas ist schief gelaufen. Klicken Sie auf „Neu laden“, um die App neu zu starten, oder kontaktieren Sie uns, wenn das Problem weiterhin besteht.',
      reset: 'Neu laden',
    },
    en: {
      title: 'An unexpected error has occurred',
      text: 'Something went wrong. Press Reload to restart the app or contact us if the problem persists.',
      reset: 'Reload',
    },
    // machine-translated
    es: {
      title: 'Se ha producido un error inesperado',
      text: 'Algo salió mal. Presione Recargar para reiniciar la aplicación o contáctenos si el problema persiste.',
      reset: 'Recargar',
    },
    // machine-translated
    fr: {
      title: "Une erreur inattendue s'est produite",
      text: "Quelque chose s'est mal passé. Appuyez sur Recharger pour redémarrer l'application ou contactez-nous si le problème persiste.",
      reset: 'Recharger',
    },
    // machine-translated
    it: {
      title: 'Si è verificato un errore inaspettato',
      text: "Qualcosa è andato storto. Premi Ricarica per riavviare l'app o contattaci se il problema persiste.",
      reset: 'Ricarica',
    },
    // machine-translated
    cs: {
      title: 'Došlo k neočekávané chybě',
      text: 'Něco se pokazilo. Stiskněte znovu načíst pro restartování aplikace nebo nás kontaktujte, pokud problém přetrvává.',
      reset: 'Znovu načíst',
    },
    // machine-translated
    hu: {
      title: 'Váratlan hiba történt',
      text: 'Valami hiba történt. Nyomja meg az Újratöltés gombot az alkalmazás újraindításához, vagy lépjen kapcsolatba velünk, ha a probléma továbbra is fennáll.',
      reset: 'Újratölt',
    },
    // machine-translated
    pl: {
      title: 'Wystąpił nieoczekiwany błąd',
      text: 'Coś poszło nie tak. Naciśnij Przeładuj, aby ponownie uruchomić aplikację lub skontaktuj się z nami, jeśli problem będzie się powtarzał.',
      reset: 'Przeładuj',
    },
    // machine-translated
    pt: {
      title: 'Ocorreu um erro inesperado',
      text: 'Algo deu errado. Pressione Recarregar para reiniciar o aplicativo ou entre em contato conosco se o problema persistir.',
      reset: 'Recarregar',
    },
    // machine-translated
    sk: {
      title: 'Nastala neočakávaná chyba',
      text: 'Niečo sa pokazilo. Stlačte Obnoviť, aby reštartovali aplikáciu alebo nás kontaktujte, ak problém pretrváva.',
      reset: 'Obnoviť',
    },
  }

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <UIProvider>
        <ErrorDetailsOverlay error={error} />

        <AlertDialog
          isCentered
          isOpen
          leastDestructiveRef={resetRef}
          onClose={noop}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader>
                <FormattedMessage id="title" />
              </AlertDialogHeader>
              <AlertDialogBody>
                <FormattedMessage id="text" />
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  ref={resetRef}
                  leftIcon={<Icon symbol="arrows-refresh-cw" size="sm" />}
                  onClick={() => {
                    resetErrorBoundary()
                  }}>
                  <FormattedMessage id="reset" />
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </UIProvider>
    </IntlProvider>
  )
}

export function AppErrorBoundary({ children }: { children: React.ReactNode }) {
  return (
    <ErrorBoundary
      FallbackComponent={Fallback}
      onReset={() => {
        window.location.reload()
      }}
      onError={(error, { componentStack }) => {
        captureException(error, {
          level: 'fatal',
          tags: { errorBoundary: 'app' },
          contexts: { react: { componentStack } },
        })
      }}>
      {children}
    </ErrorBoundary>
  )
}
