import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * A hook that returns the value of a URL search parameter.
 *
 * @param paramName - The name of the search parameter to retrieve
 * @returns {string|null} The value of the search parameter, or null if it does not exist.
 *
 * @example
 * ```tsx
 * const query = useSearchParam('query')
 * ```
 */
export const useSearchParam = (paramName: string): string | null => {
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  return searchParams.get(paramName)
}
