import { HOUR } from '@/core/constants/constants'

import { createApiMethod, type } from './utils/createApiMethod'

export type Company = {
  background_image_id?: string
  color?: string
  email_domains?: string[]
  enable_stripe_management?: boolean
  logo_id?: string
  name?: string
  is_user_signup_email_edit_disabled?: boolean
  support_person?: SupportPerson
  unlimited?: boolean
}

export type SupportPerson = {
  email?: string
  gender?: string
  name?: string
  phone?: string
  picture_id?: string
}

export const company = {
  details: createApiMethod({
    method: 'GET',
    path: '/company',
    response: type<Company>(),
  }),

  update: createApiMethod({
    method: 'PUT',
    path: '/company',
    data: type<{
      is_user_signup_email_edit_disabled: boolean
      logo_data: string
    }>(),
  }),

  noEmailUsers: {
    details: createApiMethod({
      method: 'GET',
      path: '/company/:ssoSourceId/no_email_users',
      response: type<Record<string, never>>(),
    }),
  },
}

export const companyQueries = {
  all: () => ['company'] as const,
  detail: () => ({
    queryKey: [...companyQueries.all(), 'detail'] as const,
    queryFn: () => company.details(),
    staleTime: HOUR * 12,
  }),
}
