import * as v from 'valibot'

const StringToBooleanSchema = v.pipe(
  v.optional(v.union([v.literal('true'), v.literal('false')])),
  v.transform((value) => value === 'true'),
)

const EnvSchema = v.object({
  DEV: v.optional(v.boolean()),
  PROD: v.optional(v.boolean()),
  MODE: v.union([
    v.literal('development'),
    v.literal('test'),
    v.literal('production'),
    v.literal('staging'),
    v.literal('staging-qa'),
    v.literal('staging-release'),
  ]),
  APP_VERSION: v.optional(v.string()),

  REACT_APP_PROXY: v.optional(v.string()),
  REACT_APP_API_URL_PREFIX: v.string(),
  REACT_APP_DOMAIN: v.optional(v.string()),
  REACT_APP_FEATURES: v.optional(v.string()),
  REACT_APP_PERSIST_ALL: StringToBooleanSchema,

  REACT_APP_MSW_ENABLED: StringToBooleanSchema,

  REACT_APP_REDUX_LOGGER_ENABLED: StringToBooleanSchema,

  REACT_APP_SENTRY_DSN: v.optional(v.string()),

  REACT_APP_CIRCLE_SHA1: v.optional(v.string()),

  REACT_APP_LOGROCKET_KEY: v.optional(v.string()),

  REACT_APP_KEYCLOAK_REALM: v.string(),
  REACT_APP_KEYCLOAK_CLIENTID: v.string(),
  REACT_APP_KEYCLOAK_CUSTOM_URL: v.optional(v.string()),
  REACT_APP_KEYCLOAK_SUBDOMAIN: v.optional(v.string()),
  REACT_APP_KEYCLOAK_PATHNAME: v.string(),
  REACT_APP_KEYCLOAK_URL: v.optional(v.string()),

  REACT_APP_CLOUDINARY_CLOUD_NAME: v.optional(v.string()),
  REACT_APP_CLOUDINARY_PRIVATE_CDN: StringToBooleanSchema,
  REACT_APP_CLOUDINARY_CNAME: v.optional(v.string()),

  REACT_APP_USERPILOT_TOKEN: v.optional(v.string()),

  REACT_APP_PUBLIC_POSTHOG_KEY: v.optional(v.string()),
  REACT_APP_PUBLIC_POSTHOG_API_HOST: v.optional(v.string()),
  REACT_APP_PUBLIC_POSTHOG_UI_HOST: v.optional(v.string()),

  REACT_APP_MIXPANEL_KEY: v.optional(v.string()),
  REACT_APP_MIXPANEL_DEBUG: StringToBooleanSchema,

  REACT_APP_SCORM_ENABLED: StringToBooleanSchema,
  REACT_APP_SCORM_LAMBDA_GENERATOR_URL: v.optional(v.string()),
  REACT_APP_SCORM_S3_DOMAIN: v.optional(v.string()),

  REACT_APP_KNOCK_PUBLIC_API_KEY: v.optional(v.string()),
  REACT_APP_KNOCK_FEED_ID: v.optional(v.string()),

  REACT_APP_CLOUDFLARE_ACCOUNT_ID: v.optional(v.string()),
  REACT_APP_ZENDESK: v.optional(v.string()),
})

export const result = v.safeParse(EnvSchema, import.meta.env)

if (!result.success) {
  const errorMessage = v.flatten<typeof EnvSchema>(result.issues)
  console.error(
    'Invalid environment variables:',
    JSON.stringify(errorMessage, null, 2),
  )
  if (import.meta.env.MODE !== 'production') {
    throw new Error('Invalid environment variables')
  }
}

export const env = result.success
  ? result.output
  : (process.env as unknown as v.InferOutput<typeof EnvSchema>)
