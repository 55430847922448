import { useSessionStorage } from 'react-use'

import useSearchParams from '@/core/hooks/useSearchParams'
import useScormConfiguration from '@/scorm/hooks/useScormConfiguration'

/**
 * Hook that manages content locking functionality.
 *
 * This hook is used to restrict navigation within the platform, ensuring users
 * can only access specific learning materials through direct links. It combines
 * lock settings from URL parameters and SCORM configuration.
 *
 * The content lock state is:
 * - Initialized from URL parameters (lock_content=true)
 * - Persisted in session storage
 * - Can be influenced by SCORM configuration
 *
 * @returns {boolean} lockContent - Whether content should be locked/restricted
 */
export function useContentLock() {
  const scormConfiguration = useScormConfiguration()
  const searchParams = useSearchParams()

  const [lockContent, setLockContent] = useSessionStorage(
    'lock_content',
    searchParams.lock_content === 'true',
  )

  if (searchParams.lock_content === 'true' && lockContent === false) {
    setLockContent(true)
  }

  return { lockContent: lockContent || scormConfiguration.lockContent === true }
}
