import classNames from 'classnames'
import { ReactNode, Ref, forwardRef } from 'react'

import { Box } from '@masterplandev/ui'

import { useContentLock } from '@/app/student/hooks/useContentLock'
import PageBottomSensor from '@/core/components/PageBottomSensor/PageBottomSensor'
import Container from '@/core/components/ui/Container/Container'
import { DOM_ID_PAGE } from '@/core/constants/constants'

import styles from './PageWrapper.module.css'

interface Props {
  renderFooter?: ReactNode
  beforeContent?: ReactNode
  children?: ReactNode
  contentClassName?: string
  loaded?: boolean
  noContainer?: boolean
  wrapperClassName?: string
}

export const PageWrapper = forwardRef(
  (
    {
      renderFooter,
      beforeContent,
      children = null,
      contentClassName,
      loaded = true,
      noContainer,
      wrapperClassName,
    }: Props,
    ref: Ref<HTMLDivElement>,
  ) => {
    const { lockContent } = useContentLock()

    if (!loaded) {
      return null
    }

    const ContentWrapper = noContainer ? 'div' : Container

    return (
      <Box
        minH={
          lockContent
            ? '100vh'
            : 'calc(100vh - var(--page--navigation--height))'
        }
        ref={ref}
        className={classNames(styles.page, wrapperClassName)}>
        {beforeContent}
        <ContentWrapper
          className={classNames(styles.content, contentClassName)}
          id={DOM_ID_PAGE}>
          {children}
        </ContentWrapper>
        {renderFooter}
        <PageBottomSensor />
      </Box>
    )
  },
)
