import { isArray, pickBy } from 'lodash/fp'

/**
 * Removes empty values from a query parameters object.
 *
 * @template T - The type of the input object extending Record<string, unknown>
 * @param {T} [params] - The query parameters object to process
 * @returns {Partial<T>} A new object with empty values removed:
 *   - Empty arrays (length === 0) are removed
 *   - Empty strings ('') are removed
 *   - Other falsy values (null, undefined, 0, false) are preserved
 *
 * @example
 * ```typescript
 * const params = {
 *   search: '',           // empty string - will be removed
 *   tags: [],            // empty array - will be removed
 *   status: 'active',    // non-empty string - will be kept
 *   count: 0,            // number - will be kept
 *   isEnabled: false,    // boolean - will be kept
 *   roles: ['admin']     // non-empty array - will be kept
 * }
 *
 * stripEmptyQueryParams(params)
 * // Result: {
 * //   status: 'active',
 * //   count: 0,
 * //   isEnabled: false,
 * //   roles: ['admin']
 * // }
 * ```
 */
export function stripEmptyQueryParams<T extends Record<string, unknown>>(
  params?: T,
): Partial<T> {
  return pickBy(
    (value) => {
      if (isArray(value)) {
        return value.length > 0
      }
      if (typeof value === 'string') {
        return value !== ''
      }
      return true
    },
    params ?? ({} as T),
  ) as Partial<T>
}
