import {
  compact,
  curry,
  defaultTo,
  endsWith,
  eq,
  filter,
  find,
  flatten,
  get,
  getOr,
  head,
  identity,
  isEmpty,
  last,
  map,
  mergeAll,
  negate,
  over,
  pick,
  pipe,
  placeholder,
  size,
} from 'lodash/fp'
import createCachedSelector from 're-reselect'
import { Selector, createSelector } from 'reselect'

import { getPercentage, passProps, silentAttempt } from '@masterplandev/utils'

import { ManagedLearnpathElementOneOf } from '@/api/generated-api-and-types'
import { generateBasicSelectors } from '@/core/selectors'
import buildLectureLink from '@/core/utils/links/buildLectureLink'
import cachedKeyCreator from '@/core/utils/redux/cachedKeyCreator'
import createMemoizeByKeysSelector from '@/core/utils/redux/createMemoizeByKeysSelector'
import { FILE_EXTENSION_PDF } from '@/form/constants'
import { ELEMENT_TYPE_PLATFORM_TOPIC } from '@/learnpaths/constants'
import { isElementOfType } from '@/learnpaths/utils/isElementOfType'
import getFirstUnlockedOrStartedAfterElement from '@/lecture/utils/getFirstUnlockedOrStartedAfterElement'

import { LearnpathAssignedState } from './types/LearnpathAssignedState'
import buildApiUrl from './utils/buildApiUrl'

type State = {
  learnpathsAssigned: LearnpathAssignedState
}

export const assignedLearnpathsRootSelector: Selector<
  State,
  LearnpathAssignedState
> = getOr({} as LearnpathAssignedState, 'learnpathsAssigned')

export const assignedLectureSelectors = generateBasicSelectors(
  createCachedSelector(
    [assignedLearnpathsRootSelector, passProps],
    (root, { topic, lecture, learnpathId, elementId }) =>
      getOr(
        {},
        buildLectureLink({
          topic,
          lecture,
          learnpathId,
          elementId,
        }),
        root,
      ),
  )(cachedKeyCreator),
)

export const assignedLearnpathAllSelectors = generateBasicSelectors(
  createCachedSelector(
    [assignedLearnpathsRootSelector, passProps],
    (root, props: { courses: boolean }) =>
      getOr(
        {},
        props?.courses
          ? buildApiUrl.assignedCourses()
          : (buildApiUrl.assigned() as '/learnpaths/assigned'),
        root,
      ),
  )(cachedKeyCreator),
)

export const assignedLearnpathAllSizeSelectors = createSelector(
  assignedLearnpathAllSelectors.data,
  size,
)

export const assignedLearnpathAllListSelector = createSelector(
  [
    createMemoizeByKeysSelector(/^\/learnpaths\/assigned\/[^/.]*$/)(
      assignedLearnpathsRootSelector,
      identity,
    ),
    assignedLearnpathAllSelectors.data,
  ],
  (root, learnpathIds: string[]) => {
    if (learnpathIds && root) {
      const idSelector = (id) => get([buildApiUrl.assigned(id), 'data'], root)
      return map(idSelector, learnpathIds)
    }

    return []
  },
)

export const isAnyLearnpathCompletedSelector = createSelector(
  assignedLearnpathAllListSelector,
  pipe([
    filter(({ progress: { num_elements, num_elements_completed } }) =>
      eq(num_elements, num_elements_completed),
    ),
    negate(isEmpty),
  ]),
)

export const assignedLearnpathSelectors = generateBasicSelectors(
  createCachedSelector(
    [assignedLearnpathsRootSelector, passProps],
    (root, { learnpathId }) =>
      getOr({}, buildApiUrl.assigned(learnpathId), root),
  )(cachedKeyCreator),
)

export const assignedLearnpathProgressSelector = createSelector(
  [assignedLearnpathSelectors.data],
  get('progress'),
)

export const assignedLearnpathPointsSelector = createSelector(
  [assignedLearnpathSelectors.data],
  get('points'),
)

export const assignedLearnpathProgressCompletion = createSelector(
  assignedLearnpathProgressSelector,
  (
    {
      num_items,
      num_items_completed,
    }: { num_items: number; num_items_completed: number } = {
      num_items: 0,
      num_items_completed: 0,
    },
  ) => {
    return (getPercentage(num_items_completed, num_items) ?? 0) / 100
  },
)

export const assignedLearnpathElementIdsSelector = createSelector(
  assignedLearnpathSelectors.data,
  get('elements'),
)

export const assignedLearnpathIsFinishedSelector = createSelector(
  assignedLearnpathSelectors.data,
  (learnpath) => {
    return eq(
      get('progress.num_elements', learnpath),
      get('progress.num_elements_completed', learnpath),
    )
  },
)

export const assignedLearnpathIsLinearSelector = createSelector(
  assignedLearnpathSelectors.data,
  get('linear'),
)

export const assignedLearnpathDurationSelector = createSelector(
  assignedLearnpathSelectors.data,
  get('duration'),
)

export const assignedLearnpathIsCourseSelector = createSelector(
  assignedLearnpathSelectors.data,
  get('is_course'),
)

export const assignedLearnpathIsMandatorySelector = createSelector(
  assignedLearnpathSelectors.data,
  getOr(false, 'mandatory'),
)

export const assignedElementSelectors = generateBasicSelectors(
  createCachedSelector(
    [assignedLearnpathsRootSelector, passProps],
    (root, { learnpathId, elementId }) =>
      getOr({}, buildApiUrl.assignedElement(learnpathId, elementId), root),
  )(cachedKeyCreator),
)

const originalAssignedElementDataSelector = assignedElementSelectors.data
// @ts-ignore
assignedElementSelectors.data = createCachedSelector(
  [originalAssignedElementDataSelector, passProps],
  (assignedElement: ManagedLearnpathElementOneOf, { flattenData }) => {
    if (isElementOfType(assignedElement, 'platform_topic') && flattenData) {
      return {
        ...assignedElement.topic,
        ...assignedElement,
      }
    }

    return assignedElement
  },
)(cachedKeyCreator)

export const assignedElementListSelector = createCachedSelector(
  [
    createMemoizeByKeysSelector(/^\/learnpaths\/assigned\/.*\/elements\/.*$/)(
      assignedLearnpathsRootSelector,
      identity,
    ),
    assignedLearnpathElementIdsSelector,
    passProps,
  ],
  (root, elementIds, { learnpathId, flattenData }) => {
    if (elementIds && root) {
      const getElement = (elementId) =>
        get([buildApiUrl.assignedElement(learnpathId, elementId), 'data'], root)

      return map(
        (elementId) =>
          assignedElementSelectors.data.resultFunc(getElement(elementId), {
            flattenData,
          }),
        elementIds,
      )
    }

    return []
  },
)(cachedKeyCreator)

export const assignedElementIsLastSelector = createCachedSelector(
  [assignedLearnpathElementIdsSelector, passProps],
  (ids, { elementId }) => eq(last(ids), elementId),
)(cachedKeyCreator)

export const assignedElementProgressStatusSelector = createSelector(
  [assignedElementSelectors.data],
  get('progress.status'),
)

export const assignedElementTypeSelector = createSelector(
  [assignedElementSelectors.data],
  get('type'),
)

export const assignedElementTopicSelector = createSelector(
  [assignedElementSelectors.data],
  get('topic'),
)

export const assignedElementThumbnailUrlSelector = createSelector(
  [assignedElementSelectors.data],
  get('thumbnail_url'),
)

export const assignedElementVideoIdSelector = createSelector(
  [assignedElementSelectors.data],
  get('video_id'),
)

export const assignedElementScormPackageSelector = createSelector(
  assignedElementSelectors.data,
  get('scorm_package'),
)

export const assignedElementScormPackageIdSelector = createSelector(
  assignedElementSelectors.data,
  get('scorm_package.id'),
)

export const assignedElementCompletionTriggerSelector = createSelector(
  assignedElementSelectors.data,
  get('completion_trigger'),
)

export const assignedElementStatusMapSelector = createSelector(
  [
    assignedLearnpathsRootSelector,
    assignedLearnpathElementIdsSelector,
    passProps,
  ],
  (
    root,
    learnpathElementIds,
    { learnpathId }: { learnpathId: string } = { learnpathId: '' },
  ) =>
    pipe([
      map((elementId: string) => ({
        [elementId]: get(
          [
            buildApiUrl.assignedElement(learnpathId, elementId),
            'data',
            'progress',
            'status',
          ],
          root,
        ),
      })),
      mergeAll,
      defaultTo({}),
    ])(learnpathElementIds),
)

export const assignedElementActiveStatusSelector = createCachedSelector(
  [assignedElementSelectors.meta, assignedElementSelectors.data],
  (meta: { activeStatus?: string }, data) =>
    silentAttempt(() => localStorage.elementState) ||
    meta.activeStatus ||
    get('progress.status', data),
)(cachedKeyCreator)

export const assignedNextElementUrlSelector = createSelector(
  [assignedElementListSelector, assignedLearnpathIsLinearSelector, passProps],
  (
    assignedLectureList,
    learnpathIsLinear,
    {
      learnpathId,
      elementId,
      lecture: lectureSlug,
    }: { learnpathId: string; elementId: string; lecture?: string },
  ) => {
    if (!learnpathId || !elementId || isEmpty(assignedLectureList)) {
      return null
    }

    const buildElementUrl = curry((params, element) =>
      element ? buildLectureLink({ ...params, elementId: element.id }) : null,
    )
    const buildLectureUrl = curry((params, lecture) =>
      lecture ? buildLectureLink({ ...params, lecture: lecture.slug }) : null,
    )
    const getPlatformTopicLectureUrlAfter = curry((element, lecture) =>
      element?.type === ELEMENT_TYPE_PLATFORM_TOPIC
        ? pipe([
            get('topic.lectures'),
            getFirstUnlockedOrStartedAfterElement({
              slug: learnpathIsLinear ? null : lecture,
            }),
            buildLectureUrl({
              learnpathId,
              elementId: element.id,
              topic: element.topic.slug,
            }),
          ])(element)
        : null,
    )

    return pipe([
      over([
        pipe([
          find({ id: elementId }),
          getPlatformTopicLectureUrlAfter(placeholder, lectureSlug),
        ]),
        pipe([
          getFirstUnlockedOrStartedAfterElement({ id: elementId }),
          over([
            getPlatformTopicLectureUrlAfter(placeholder, null),
            buildElementUrl({ learnpathId }),
          ]),
        ]),
      ]),
      flatten,
      compact,
      head,
    ])(assignedLectureList)
  },
)

export const assignedElementFileDataSelector = createSelector(
  assignedElementSelectors.data,
  pick(['file_url', 'filename', 'filesize', 'thumbnail_url']),
)

export const assignedElementDownloadableSelector = createSelector(
  assignedElementSelectors.data,
  get('downloadable'),
)

export const assignedElementFilenameSelector = createSelector(
  assignedElementFileDataSelector,
  get('filename'),
)

export const assignedElementFilesizeSelector = createSelector(
  assignedElementFileDataSelector,
  get('filesize'),
)

export const assignedElementFileUrlSelector = createSelector(
  assignedElementFileDataSelector,
  get('file_url'),
)

export const assignedElementIsPDFSelector = createSelector(
  assignedElementFileDataSelector,
  ({ filename }) => endsWith(FILE_EXTENSION_PDF, filename),
)
