import {
  castArray,
  forEach,
  includes,
  isArray,
  isEmpty,
  keys,
  map,
  omit,
  reduce,
  toPairs,
} from 'lodash/fp'
import { createActions } from 'redux-actions'
import * as v from 'valibot'

import { urlParamsToObject } from '@/core/utils/urlParamsToObject'

import { mediaSummaryFilterSelector } from '../selectors/mediaSummary.selectors'

const mediaSummaryFiltersSchema = v.partial(
  v.object({
    search: v.string(),
  }),
)

const eventsSchema = v.partial(
  v.object({
    owner: v.union([v.literal('me')]),
    type: v.array(v.union([v.literal('in_person'), v.literal('online')])),
    user: v.custom((val) =>
      typeof val === 'string' ? /^.+:.+$/.test(val) : false,
    ),
    state: v.union([
      v.literal('upcoming'),
      v.literal('overdue'),
      v.literal('unassigned'),
      v.literal('active'),
      v.literal('in_progress'),
    ]),
    group: v.custom((val) =>
      typeof val === 'string' ? /^.+:.+$/.test(val) : false,
    ),
  }),
)

const learnpathsSchema = v.partial(
  v.object({
    language: v.string(),
    owner: v.string(),
    types: v.array(
      v.union([
        v.literal('mandatory'),
        v.literal('linear'),
        v.literal('certificate'),
      ]),
    ),
    user: v.custom((val) =>
      typeof val === 'string' ? /^.+:.+$/.test(val) : false,
    ),
    status: v.union([
      v.literal('active'),
      v.literal('draft'),
      v.literal('inactive'),
    ]),
    group: v.custom((val) =>
      typeof val === 'string' ? /^.+:.+$/.test(val) : false,
    ),
  }),
)

const paramsSchema = v.object({
  ...eventsSchema.entries,
  ...mediaSummaryFiltersSchema.entries,
  ...learnpathsSchema.entries,
})

const setNewSearch = (searchParams, history) => {
  if (searchParams.size === 0) {
    history.push(history.location.pathname)
  } else {
    history.push(`${history.location.pathname}?${searchParams.toString()}`)
  }
}

// Function sanitizes search parameters against existing filter schema. Faulty URL
// entries are ignored. Valibot is used to validate the schema.
const sanitizeParams = (searchParams) => {
  const parsedString = urlParamsToObject(searchParams)
  const arrayAssured = reduce(
    (acc, key) => {
      if (paramsSchema.entries[key].wrapped.type === 'array') {
        return { ...acc, [key]: castArray(parsedString[key]) }
      }

      return { ...acc, [key]: parsedString[key] }
    },
    {},
    keys(paramsSchema.entries),
  )

  const parsedFilter = v.safeParse(paramsSchema, arrayAssured)

  if (parsedFilter.success) {
    return parsedFilter.output
  }

  return omit(map('path[0].key', parsedFilter.issues), parsedFilter.output)
}

const actions = {
  mediaSummary: createActions(
    'CLEAR_SELECTED_ROWS',
    'INCREMENT_DIALOG_ITEMS_FINISHED',
    'REMOVE_ROW_SELECTION',
    'RESET',
    'SET_DETAILS_ANIMATION_MEDIA_ID',
    'SET_DIALOG',
    'SET_FILTERS',
    'SET_MEDIA_ID',
    'SET_PAGE_INDEX',
    'SET_PAGE_SIZE',
    'SET_PAGE_SORTING',
    'SET_SHOW_ONLY_SELECTED',
    'SET_TAB',
    'TOGGLE_ROW_SELECTION',
    'TOGGLE_SHOW_ONLY_SELECTED',
  ),
}

const setFilters = async (dispatch, history, searchParams) => {
  setNewSearch(searchParams, history)
  return Promise.all([
    dispatch(actions.mediaSummary.setFilters(sanitizeParams(searchParams))),
    dispatch(actions.mediaSummary.setPageIndex(0)),
  ])
}

actions.mediaSummary.initialize =
  (initialState, history) => async (dispatch) => {
    const searchParams = new URLSearchParams(history.location.search)
    await dispatch(actions.mediaSummary.reset(initialState ?? {}))

    if (!isEmpty(initialState?.filters)) {
      forEach(([filter, newValue]) => {
        if (isArray(newValue)) {
          searchParams.delete(filter)
          forEach((value) => searchParams.append(filter, value), newValue)
        } else {
          searchParams.set(filter, newValue)
        }
      }, toPairs(initialState.filters))

      return setFilters(dispatch, history, searchParams)
    }

    return Promise.resolve()
  }

actions.mediaSummary.appendFilter =
  (key, newValue, history) => (dispatch, getState) => {
    const searchParams = new URLSearchParams(history.location.search)
    const currentFilters = mediaSummaryFilterSelector(getState(), {
      filter: key,
    })

    if (!currentFilters || isArray(currentFilters)) {
      searchParams.append(key, newValue)
      setFilters(dispatch, history, searchParams)
    }
  }

actions.mediaSummary.updateFilter = (key, newValue, history) => (dispatch) => {
  const searchParams = new URLSearchParams(history.location.search)

  if (isArray(newValue)) {
    searchParams.delete(key)
    forEach((value) => searchParams.append(key, value), newValue)
  } else {
    searchParams.set(key, newValue)
  }

  setFilters(dispatch, history, searchParams)
}

actions.mediaSummary.removeFilter =
  (key, oldValue, history) => (dispatch, getState) => {
    const searchParams = new URLSearchParams(history.location.search)

    const values = mediaSummaryFilterSelector(getState(), { filter: key })
    if (isArray(values) && oldValue) {
      const newArray = values.filter((item) => item !== oldValue)

      if (isEmpty(newArray)) {
        searchParams.delete(key)
      } else {
        searchParams.set(key, newArray)
      }
    } else {
      searchParams.delete(key)
    }

    setFilters(dispatch, history, searchParams)
  }

actions.mediaSummary.toggleFilter =
  (key, value, history) => (dispatch, getState) => {
    const searchParams = new URLSearchParams(history.location.search)

    const values = mediaSummaryFilterSelector(getState(), { filter: key })
    if (isArray(values)) {
      if (includes(value, values)) {
        const newArray = values.filter((item) => item !== value)

        if (isEmpty(newArray)) {
          searchParams.delete(key)
        } else {
          searchParams.delete(key)
          forEach((val) => searchParams.append(key, val), newArray)
        }
      } else {
        searchParams.append(key, value)
      }
    } else if (searchParams.has(key)) {
      searchParams.delete(key)
    } else {
      searchParams.set(key, value)
    }

    setFilters(dispatch, history, searchParams)
  }

actions.mediaSummary.clearFilters = (history) => (dispatch) => {
  setFilters(dispatch, history, new URLSearchParams())
}

export default actions.mediaSummary
