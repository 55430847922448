import { flatten, keys, omit, pipe } from 'lodash/fp'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'

import { UrlBuilder, includesAny } from '@masterplandev/utils'

/**
 * Utility hook that returns redirect function, which behaves as history.replace
 * but is meant to omit some query parameters.
 *
 * @param {Array} omitParams - query parameters to be omitted when redirecting.
 * Can be passed as an array or parameter list.
 *
 * @returns {object} Object with redirect function in it.
 *
 * @example
 * const { redirect } = useRedirectOmitParams('t', 'some-param')
 * redirect()
 *
 * const { redirect } = useRedirectOmitParams(['t', 'some-param'])
 * redirect()
 */
export default function useRedirectOmitParams(...omitParams) {
  const history = useHistory()
  const qParams = queryString.parse(history.location.search)
  const omitted = flatten(omitParams)

  return {
    redirect() {
      if (pipe([keys, includesAny(omitted)])(qParams)) {
        history.replace(
          UrlBuilder(history.location.pathname)
            .addQuery(omit(omitted, qParams))
            .toString(),
        )
      }
    },
  }
}
