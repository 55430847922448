export const APP_LOCALE_FEATURED = [
  'es',
  'fr',
  'it',
  'cs',
  'hu',
  'pl',
  'pt',
  'sk',
] as const
export const APP_LOCALE_FALLBACK = 'en'
export const APP_LOCALE = ['de', 'en', ...APP_LOCALE_FEATURED] as const

export const CONTENT_LOCALE = [...APP_LOCALE, 'ru', 'nl'] as const
