import LogRocket from 'logrocket'
import posthog from 'posthog-js'
import { Userpilot } from 'userpilot'

import { eventTracker } from '@masterplandev/utils'

import { env } from '@/env'

import { TrackingEvent } from './types/TrackingEvent'

window.dataLayer = window.dataLayer || []

export const track = <T extends keyof TrackingEvent>(
  eventName: T,
  params?: TrackingEvent[T],
) => {
  eventTracker.track(eventName, {
    $referrer: document.referrer,
    $current_url: window.location.href,
    ...params,
  })
  LogRocket.log(`mixpanel ${eventName}`, params)
  window.dataLayer.push({
    event: eventName,
    ...params,
  })

  if (env.MODE !== 'test') {
    const { event, ...rest } = eventTracker.get_event(eventName, params)
    posthog.capture(event, rest)
    Userpilot.track(event, rest)
  }
}

export default track
