import { includes, isEmpty, omit } from 'lodash/fp'
import { createActions } from 'redux-actions'

import { debounceWithOptions } from '@masterplandev/utils'

import { AssignedLearnpathElementProgress } from '@/api/generated-api-and-types'
import { QueryKeys } from '@/api/queryKeys'
import { LectureProgressMeta } from '@/api/types/LectureProgressMeta'
import { queryClient } from '@/core/utils/react-query/queryClient'
import dashboardActions from '@/dashboard/actions'
import learnpathAssignedActions from '@/learnpaths-assigned/actions'
import { assignedLearnpathPointsSelector } from '@/learnpaths-assigned/selectors'

import { universalLectureProgressStatusSelector } from '../selectors/universalLecture.selectors'
import buildUniversalLectureApiUrl from '../utils/buildUniversalLectureApiUrl'
import toMatchParams from '../utils/toMatchParams'

const actions = createActions({
  LECTURE: {
    SET_ACTIVE_STATUS: (params) => ({
      url: buildUniversalLectureApiUrl(params),
      params,
    }),
    END_ACTIVE_STATUS: (params) => ({
      url: buildUniversalLectureApiUrl(params),
      params,
    }),
    PLAY_VIDEO: (params) => ({
      url: buildUniversalLectureApiUrl(params),
      params,
    }),
    STOP_VIDEO: (params) => ({
      url: buildUniversalLectureApiUrl(params),
      params,
    }),
    PROGRESS_PUT: (params, data) => ({
      options: {
        url: buildUniversalLectureApiUrl(params),
      },
      request: {
        url: buildUniversalLectureApiUrl(params, {
          suffix: 'progress',
          withContext: true,
        }),
        method: 'put',
        data,
      },
      params,
    }),
    PROGRESS_UPDATE_STORE: (params, progress) => ({
      url: buildUniversalLectureApiUrl(params),
      progress,
      params,
    }),
  },
})

// For frequent updates like text scrolling or video we need to debounce the progress function.
const LECTURE_PROGRESS_DEBOUNCE_TIME = 1000
const debounceProgress = debounceWithOptions(
  LECTURE_PROGRESS_DEBOUNCE_TIME,
  (dispatch, params, data) =>
    dispatch(actions.lecture.progressPut(params, data)),
  { maxWait: 5000, leading: true, trailing: true },
)

actions.lecture.progress = (
  params,
  data: AssignedLearnpathElementProgress,
  lectureContext?: LectureProgressMeta['context'],
) =>
  function progress(dispatch, getState) {
    const currentStatus = universalLectureProgressStatusSelector(
      getState(),
      toMatchParams(params),
    )

    const progressWithoutStatus = omit('status', data)

    const progressDataWithContext = {
      ...data,
      meta: {
        ...data.meta,
        context: lectureContext ?? null,
      },
    }

    // Do not try to update progress meta if there's nothing passed.
    if (!isEmpty(progressWithoutStatus)) {
      dispatch(
        actions.lecture.progressUpdateStore(params, progressWithoutStatus),
      )
    }

    // If there is no current status pass values as it is, otherwise allow calls to be made
    // only if status changes.
    if (
      !currentStatus ||
      (currentStatus === 'unlocked' &&
        includes(data?.status, ['started', 'viewed', 'completed'])) ||
      (currentStatus === 'started' &&
        includes(data?.status, ['viewed', 'completed']))
    ) {
      // We need to cancel any previous debounced function and update progress on the server
      // immediately after the change of status was detected.
      debounceProgress.cancel()
      return dispatch(
        actions.lecture.progressPut(params, progressDataWithContext),
      ).then(() => {
        if (params.learnpathId && params.elementId) {
          // Data seeding and syncing between what is inside redux and react-query
          // (react query is used inside learnpaths-assigned-element module).
          // The logic is executed from reducers and since redux is being removed in favour of
          // react-query inside learnpaths-assigned-element that kind of syncing is required.
          // The invocations are made:
          // frontend/src/lecture/actions/progress.actions.ts
          // frontend/src/learnpaths-assigned/utils/stateNormalise.ts
          // frontend/src/learnpaths-assigned/reducers/lectures.reducer.ts
          const syncCalls: Promise<any>[] = [
            queryClient.invalidateQueries({
              queryKey: QueryKeys.learnpaths.assignedElement(
                params.learnpathId,
                params.elementId,
              ),
            }),
            // Always update entire lecture/element when status changes.
            dispatch(learnpathAssignedActions.assigned.element.get(params)),
          ]

          // Update learning path only after the element has been completed.
          if (
            !currentStatus ||
            ((currentStatus === 'unlocked' || currentStatus === 'started') &&
              includes(data?.status, ['viewed', 'completed']))
          ) {
            syncCalls.push(
              dispatch(actions.lecture.syncProgressAfterCompletion(params)),
            )
          }

          return Promise.all(syncCalls)
        }

        return null
      })
    }

    // Do not try to update progress meta on the server if there's nothing passed.
    if (!isEmpty(progressWithoutStatus)) {
      return debounceProgress(dispatch, params, progressDataWithContext)
    }

    return null
  }

actions.lecture.progressText = (params, scroll) => {
  const meta = {
    scroll,
    ...((params.meta || {}) as object),
  }
  const data = scroll >= 1 ? { status: 'viewed', meta } : { meta }
  return actions.lecture.progress(params, data)
}

actions.lecture.syncProgressAfterCompletion =
  (params: { learnpathId: string; elementId?: string }) =>
  (dispatch, getState) => {
    // If the element was completed, update entire learnpath as some additional calculations
    // might have been done on the server.
    const syncCalls: Promise<any>[] = [
      dispatch(learnpathAssignedActions.assigned.get(params)),
    ]

    // Sync score only if learnpath had points.
    if (assignedLearnpathPointsSelector(getState(), params)) {
      syncCalls.push(dispatch(dashboardActions.getScore()))
    }

    return Promise.all(syncCalls)
  }

export default actions
