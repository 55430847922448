import {
  get,
  has,
  map,
  mapValues,
  omit,
  pick,
  remove,
  some,
  startsWith,
  values,
} from 'lodash/fp'
import { combineActions } from 'redux-actions'
import { getActionTypes } from 'redux-axios-middleware'

import { mergeExceptKeys } from '@masterplandev/utils'

import actions from '@/actions'
import { LOCATION_CHANGE } from '@/core/constants/constants'
import buildLectureLink from '@/core/utils/links/buildLectureLink'
import parseLectureLink from '@/core/utils/links/parseLectureLink'
import mergeErrorAction from '@/core/utils/redux/mergeErrorAction'
import mergeRequestAction from '@/core/utils/redux/mergeRequestAction'
import mergeState from '@/core/utils/redux/mergeState'
import mergeSuccessAction from '@/core/utils/redux/mergeSuccessAction'

import { bookmarksSelector } from '../selectors/lecture.selectors'

const [GET_REQUEST, GET_SUCCESS, GET_ERROR] = getActionTypes({
  type: actions.lecture.get,
})
const [BOOKMARKS_GET_REQUEST, BOOKMARKS_GET_SUCCESS, BOOKMARKS_GET_ERROR] =
  getActionTypes({ type: actions.lecture.bookmarks.get })
const [, BOOKMARKS_POST_SUCCESS] = getActionTypes({
  type: actions.lecture.bookmarks.post,
})
const [, BOOKMARKS_PUT_SUCCESS] = getActionTypes({
  type: actions.lecture.bookmarks.put,
})
const [, BOOKMARKS_DELETE_SUCCESS] = getActionTypes({
  type: actions.lecture.bookmarks.delete,
})

const [FEEDBACK_GET_REQUEST, FEEDBACK_GET_SUCCESS, FEEDBACK_GET_ERROR] =
  getActionTypes({ type: actions.lecture.feedback.get })

export default {
  [combineActions(GET_REQUEST, BOOKMARKS_GET_REQUEST, FEEDBACK_GET_REQUEST)]:
    mergeRequestAction,
  [combineActions(GET_SUCCESS, BOOKMARKS_GET_SUCCESS, FEEDBACK_GET_SUCCESS)]:
    mergeSuccessAction,
  [combineActions(GET_ERROR, BOOKMARKS_GET_ERROR, FEEDBACK_GET_ERROR)]:
    mergeErrorAction,
  [BOOKMARKS_POST_SUCCESS]: (state, { meta, payload: { data } }) => {
    const resourceUrl = get('previousAction.payload.options.url', meta)

    const { params } = meta.previousAction.payload
    const bookmarks = bookmarksSelector(
      { lectures: state },
      { match: { params } },
    )
    return mergeState(state, {
      [resourceUrl]: {
        data: {
          bookmarks: [data, ...bookmarks],
        },
      },
    })
  },
  [BOOKMARKS_PUT_SUCCESS]: (state, { meta, payload: { data } }) => {
    const resourceUrl = get('previousAction.payload.options.url', meta)

    const { params } = meta.previousAction.payload
    const { id } = params
    const bookmarks = bookmarksSelector(
      { lectures: state },
      { match: { params } },
    )

    return mergeExceptKeys(['bookmarks'], state, {
      [resourceUrl]: {
        data: {
          bookmarks: map(
            (bookmark) => (bookmark.id === id ? data : bookmark),
            bookmarks,
          ),
        },
      },
    })
  },
  [BOOKMARKS_DELETE_SUCCESS]: (state, { meta }) => {
    const resourceUrl = get('previousAction.payload.options.url', meta)

    const { params } = meta.previousAction.payload
    const { id } = params
    const bookmarks = bookmarksSelector(
      { lectures: state },
      { match: { params } },
    )

    return mergeExceptKeys(['bookmarks'], state, {
      [resourceUrl]: {
        data: {
          bookmarks: remove({ id }, bookmarks),
        },
      },
    })
  },
  // The aim of this action handler is to clear activeStatus boolean flag when user changes route.
  [LOCATION_CHANGE]: (state, { payload: { location } }) => {
    const match = parseLectureLink(location.pathname)

    // We need to add some exceptions, which may have match but should not be considered as the same page.
    const routeToLPsList = startsWith('/learnpaths/view', location.pathname)
    const routeToLPsPage = !!match?.params?.learnpathId

    // If we pass the conditions it means we are routed to the /lecture page.
    if (match && !routeToLPsList && !routeToLPsPage) {
      // Store entry key to check for activeStatus.
      const currentUrl = buildLectureLink(
        pick(['topic', 'lecture'], match.params),
      )

      // We're jumping between tabs. Clear all statues expect the current one.
      if (state[currentUrl]?.activeStatus) {
        const newState = mapValues(omit('activeStatus'), state)
        newState[currentUrl].activeStatus = state[currentUrl]?.activeStatus
        return newState
      }

      // We're jumping to another lecture, so clear all previous active statues.
      return mapValues(omit('activeStatus'), state)
    }

    // Potentially, we're jumping out of the lecture page, clear all previous active statues.
    if (some(has('activeStatus'), values(state))) {
      return mapValues(omit('activeStatus'), state)
    }

    // Do not force re-renders if nothing changed.
    return state
  },
  [actions.lecture.feedback.clear]: (state, { payload: { url } }) =>
    omit(url, state),
}
