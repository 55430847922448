import scrollToElementOriginal from 'scroll-to-element'

const MILLISECONDS_PER_PIXEL = 1
const MAXIMUM_SCROLL_TIME = 1000
const NAVIGATION_HEIGHT_PLUS_OFFSET = -80

type Options = {
  duration?: number
  offset?: number
}

export default function scrollToElement(
  element: Element | string,
  options: Options = {},
) {
  try {
    const node =
      element instanceof Element
        ? element
        : window.document.querySelector(element)

    const offsetY = node?.getBoundingClientRect()?.y || 0

    const milliseconds = Math.abs(offsetY * MILLISECONDS_PER_PIXEL)
    const duration = Math.min(milliseconds, MAXIMUM_SCROLL_TIME)
    const offset = NAVIGATION_HEIGHT_PLUS_OFFSET

    window.requestAnimationFrame(() =>
      scrollToElementOriginal(element, { duration, offset, ...options }),
    )
  } catch (exception) {
    if (exception instanceof DOMException) {
      // We should not throw an error when the passed selector was an invalid
    } else {
      throw exception
    }
  }
}
