import { extend, get, isEmpty, isEqual, merge, pickBy, pipe } from 'lodash/fp'
import { handleActions } from 'redux-actions'
import { getActionTypes } from 'redux-axios-middleware'

import { LOCATION_CHANGE } from '@/core/constants/constants'
import parseLectureLink from '@/core/utils/links/parseLectureLink'
import clearFailed from '@/core/utils/redux/clearFailed'
import mergeErrorAction from '@/core/utils/redux/mergeErrorAction'
import mergeRequestAction from '@/core/utils/redux/mergeRequestAction'
import mergeSuccessAction from '@/core/utils/redux/mergeSuccessAction'

import actions from './actions'
import {
  currentPointsSelector,
  currentQuestionIdSelector,
  lastPointsSelector,
} from './selectors'

const [GET_REQUEST, GET_SUCCESS, GET_ERROR] = getActionTypes({
  type: actions.get,
})
const [, PUT_SUCCESS] = getActionTypes({ type: actions.put })
const [, SKIP_QUESTION_SUCCESS] = getActionTypes({ type: actions.skipQuestion })
const [, STEP_SUCCESS] = getActionTypes({ type: actions.step })

const initialState = {}

export default handleActions(
  {
    [LOCATION_CHANGE]: clearFailed,
    [GET_REQUEST]: mergeRequestAction,
    [GET_SUCCESS]: mergeSuccessAction,
    [GET_ERROR]: mergeErrorAction,
    [PUT_SUCCESS]: (state, { meta, payload }) => {
      const { data } = payload
      const resourceUrl = get('previousAction.payload.options.url', meta)

      const props = {
        match: parseLectureLink(resourceUrl),
      }

      if (isEmpty(get([resourceUrl, 'data'], state))) {
        return merge(state, {
          [resourceUrl]: {
            data,
            failed: false,
            fetched: true,
            fetching: false,
            next_data: null,
            result: null,
          },
        })
      }

      const question_id = currentQuestionIdSelector({ quiz: state }, props)
      const new_question_id = get('progress.current_question.id', data)
      const correct_answer = question_id !== new_question_id
      const received_points = correct_answer
        ? currentPointsSelector({ quiz: state }, props)
        : null
      const correct_answers_ids = get(
        `progress.answered_questions.${question_id}.correct_answers_ids`,
        data,
      )

      return merge(state, {
        [resourceUrl]: {
          data: correct_answer ? {} : data,
          failed: false,
          fetched: true,
          fetching: false,
          next_data: correct_answer ? data : null,
          result: {
            correct_answer,
            correct_answers_ids,
            received_points,
          },
        },
      })
    },
    [SKIP_QUESTION_SUCCESS]: (state, { meta, payload }) => {
      const { data } = payload
      const resourceUrl = get('previousAction.payload.options.url', meta)

      const props = {
        match: parseLectureLink(resourceUrl),
      }

      const question_id = currentQuestionIdSelector({ quiz: state }, props)
      const received_points = lastPointsSelector({ quiz: state }, props)
      const correct_answers_ids = get(
        `progress.answered_questions.${question_id}.correct_answers_ids`,
        data,
      )

      return merge(state, {
        [resourceUrl]: {
          failed: false,
          fetched: true,
          fetching: false,
          next_data: data,
          result: {
            correct_answer: true,
            correct_answers_ids,
            received_points,
            skipped: true,
          },
        },
      })
    },
    [STEP_SUCCESS]: (state, { payload, meta }) => {
      const { data: response } = payload
      const resourceUrl = get('previousAction.payload.options.url', meta)

      const request = meta.previousAction.payload.request.data
      const { answer_id } = request

      return merge(state, {
        [resourceUrl]: {
          steps: {
            [answer_id]: { request, response },
          },
        },
      })
    },
    [actions.completeStep]: (state, { payload: { url, answer_id } }) =>
      merge(state, {
        [url]: { steps: { [answer_id]: { completed: true } } },
      }),
    [actions.clearResult]: (state, { payload: { url } }) =>
      extend(state, {
        [url]: {
          ...state[url],
          result: null,
          steps: state[url].next_data
            ? null
            : pipe([
                pickBy(
                  pipe([
                    get('request.question_id'),
                    isEqual(
                      get(`${url}.data.progress.current_question.id`, state),
                    ),
                  ]),
                ),
                pickBy(get('response.correct')),
              ])(state[url].steps),
          data: state[url].next_data || state[url].data,
          next_data: null,
          previous_data: state[url].next_data
            ? state[url].data
            : state[url].previous_data,
          previous_result: state[url].next_data
            ? state[url].result
            : state[url].previous_result,
        },
      }),
    [actions.mockSuccess]: (state, { payload: { url } }) =>
      merge(state, {
        [url]: {
          result: { correct_answer: true, received_points: 5 },
        },
      }),
    [actions.mockFailure]: (state, { payload: { url } }) =>
      merge(state, {
        [url]: {
          result: { correct_answer: false, received_points: 3 },
        },
      }),
    [actions.clearAll]: () => initialState,
  },
  initialState,
)
