import { cs, de, enUS as en, es, fr, hu, it, pl, pt, sk } from 'date-fns/locale'
import {
  compact,
  concat,
  difference,
  eq,
  filter,
  flatten,
  fromPairs,
  get,
  getOr,
  head,
  intersection,
  keys,
  map,
  negate,
  omit,
  pipe,
  placeholder,
  size,
  startsWith,
} from 'lodash/fp'
import { createSelector } from 'reselect'

import { everyFalse, passProps, silentAttempt } from '@masterplandev/utils'

import { featuresSelector, userLangSelector } from '@/user/selectors'

import {
  APP_LOCALE,
  APP_LOCALE_FALLBACK,
  APP_LOCALE_FEATURED,
} from './constants'

export const rootSelector = getOr<Record<string, any>>({}, 'locale')
export const metaSelector = createSelector(rootSelector, omit('data'))
export const dataSelector = createSelector(rootSelector, getOr({}, 'data'))
export const supportedLocaleSelector = createSelector(
  featuresSelector,
  (features) => {
    const supportedLocaleByDefault = difference(APP_LOCALE, APP_LOCALE_FEATURED)
    const supportedLocaleByFeatureFlag = APP_LOCALE_FEATURED.filter(
      (langCode) => features.includes(`lang-ui-${langCode}`),
    )

    return concat(supportedLocaleByDefault, supportedLocaleByFeatureFlag)
  },
)

export const ipSelector = createSelector(dataSelector, get('ip'))

export const requiresFetchSelector = createSelector(
  metaSelector,
  ({ fetched, fetching, failed }) => everyFalse([fetched, fetching, failed]),
)

export const fetchedSelector = createSelector(metaSelector, get('fetched'))

export const localStorageLocaleSelector = () =>
  silentAttempt(() => localStorage.locale)

export const queryParamLangSelector = () => {
  const params = new URLSearchParams(location.search)
  return params.get('lang')
}

export const detectedSelector = createSelector(
  [
    dataSelector,
    localStorageLocaleSelector,
    queryParamLangSelector,
    userLangSelector,
  ],
  ({ browser, navigator }, localStorage, query, userLang) =>
    pipe([flatten, compact])([
      query,
      localStorage,
      userLang,
      browser,
      navigator,
    ]),
)

export const localeSelector = createSelector(
  [supportedLocaleSelector, detectedSelector],
  (supported, detected) =>
    pipe([intersection(supported), concat(placeholder, supported), head])(
      detected,
    ) || APP_LOCALE_FALLBACK,
)

export const dateLocaleSelector = createSelector(localeSelector, (locale) =>
  get(locale, { de, en, it, es, fr, cs, hu, pl, pt, sk }),
)

export const supportedCounterSelector = createSelector(
  supportedLocaleSelector,
  size,
)

export const messagesSelector = createSelector(passProps, get('intl.messages'))

export const createFilteredMessagesIdsSelector = (prefix) =>
  createSelector(
    messagesSelector,
    pipe([
      keys,
      filter(startsWith(`${prefix}.`)),
      filter(negate(eq(`${prefix}.undefined`))),
      map((message: string) => [
        message.replace(new RegExp(`^${prefix}\.`), ''), // eslint-disable-line no-useless-escape
        message,
      ]),
      fromPairs,
    ]),
  )

export const createFilteredMessagesSelector = (prefix) =>
  createSelector(
    messagesSelector,
    createFilteredMessagesIdsSelector(prefix),
    (messages, ids) => fromPairs(map((id) => [id, messages[id]], ids)),
  )
